<template>
    <div class="header">
        <div class="header__desc">
          МФО с наибольшим количеством одобрений на {{currentDate}}
        </div>
      <img id="fm_logo" src="../assets/Logo.png"/>
        <h1 class="header__title">
          Займы онлайн
        </h1>
        <div class="header__desc">
          Оформите минимум <span class="textHighlight">3 заявки</span>
          для <span class="textHighlight">100%</span> получения займа
        </div>
      <div class="underlinedText" v-on:click="openOfferState();" >
        Узнать о статусе заявки
      </div>
    </div>
</template>

<script>
export default {
  name: 'MainHeader',
  data() {
    return {
      currentDate : +new Date().getDate()+'.'+(new Date().getMonth()+1)+'.'+new Date().getFullYear()
    }
  },
  methods:{
    openOfferState(){
        window.onpopstate = () => {};
      this.$emit('openOfferState');
    }
  }
}
</script>

<style lang="scss">
.header {
  padding: 17px 56px 0px 56px;


  &__title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #181818;

    white-space: nowrap;
    padding-bottom:12px;
    padding-top: 24px;
    margin:0;
  }

  &__desc {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    text-align: center;
    letter-spacing: 0.03em;

    color: #000000;
  }
}
.underlinedText{
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.07em;
  text-decoration-line: underline;
  color: #181818;
  cursor:pointer;
  padding-top:12px;
}
.textHighlight{
  color: #674CF9;
  font-weight: 700;
}
#fm_logo{
  padding-top: 36px;
  //padding-bottom: 24px;
  height:30px;
  //width:44px;
}


@media screen and (max-width: 380px){
  .header {
    padding: 17px 0px 0px 0px;
    //background-color:black;
  }
}
</style>