<template>
  <div v-if="uiState=='popularOffers'" class="popularOffers">
  <div  id="greyOut"></div>
    <div class="popularOffers__popup">
      <div>
        <div class="popupTitle">Популярные предложения</div>
      </div>
      <img id="closeButton" v-on:click="closePopup()" alt="close" src="../assets/CloseButton.png">
      <div class="popupDescription">
        Мы подготовили для вас
        самые выгодные микрозаймы,
        чтобы вы не тратили время на поиск
      </div>
      <div class="contentRow">
        <offers :offers="filteredOffers" :completed-offers="completedOffers" :uiState="uiState" v-on:offerSent="onOfferSent()"  :mini="true"/>
      </div>
      <div class="popupWhitespace" />
    </div>
  </div>
</template>

<script>
import offers from './Offers.vue'

export default {
  name: 'PopularOffers',
  components:{
    offers,
  },
  props: {
    uiState: String,
    selectedOffers: [],
    completedOffers:[],
  },
  methods:{
    onOfferSent() {
      this.$emit('offerSent');
    },
    closePopup(){
      this.$emit('closePopup');
    },
    customFilter(searchedID, searchedArray){
      for (let i=0; i<this.searchedArray.length;i++){
        console.log('uwu')
        if (searchedArray[i]==searchedID)
            return true;
      }
      return false
    },
  },
  computed: {
      filteredOffers() {
        return (this.selectedOffers.filter(item=>(!this.completedOffers.includes(item.id)))).slice(0,2)
      }
  }
}
</script>

<style lang="scss">
.popularOffers{
  z-index: 99998;
  &__popup{
    z-index: 99999;


    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bacground: #F4F4F4;
    left: 0px;
    bottom:0%;
    position:  fixed;
    width: 100%;
    //height: -300px;
    background: #FAFAFA;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
}
#greyOut{
  left: 0px;
  top: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(64, 64, 64, 0.6);
}
.popupTitle{
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.07em;
  margin-top: 24px;

  color: #181818;

}
.contentRow{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
#closeButton{
  width: 20px;
  height: 20px;
  align-self: flex-end;
  position:relative;
  top: -25px;
  left: -15px;
}
.popupDescription {
  margin-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140.5%;
  max-width: 420px;
  /* or 22px */

  letter-spacing: 0.04em;

  /* Black */

  color: #181818;
}
.popupWhitespace{
  margin-bottom: 40px;
  @media(max-width: 593px){
      margin-bottom: 10px;
  }
}
</style>